import {Navigate, useLocation} from 'react-router-dom';

import type {ComponentProps} from 'react';

import {paths} from 'core/constants/paths';
import {usePermission} from 'utils/authUtils';
import {UserPermission} from 'types';

type Props = ComponentProps<any> & {
  permission?: UserPermission;
};

export default function RequireAuth({children, permission = UserPermission.EditCommand}: Props) {
  const location = useLocation();
  const hasAccess = usePermission(permission);

  if (hasAccess) {
    return children;
  }

  return <Navigate to={paths.notFound.get()} state={{referrer: location?.pathname}} />;
}
