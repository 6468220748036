import type {Auth0UserProfile} from 'auth0-js';

export enum UserPermission {
  EditCommand = 'edit:command',
  ViewCommand = 'view:command',
}

declare global {
  type Auth0UserProfileExtended = Auth0UserProfile & {
    phone?: string;
  };

  type CompanyPrimaryRoles =
    | 'Agent/Broker/Aggregator'
    | 'Carrier'
    | 'MGA/MGU/Wholesaler'
    | 'Other'
    | 'Partnerships/Association';

  type PrimaryRelationship = 'INTERMEDIARY' | 'CARRIER';

  type Parent = {
    id: string;
    name: string;
  };

  interface InsuranceCompany {
    id: string;
    name: string;
    aliases: string[];
    amBestNumber?: number;
    naic?: number;
    fein?: number;
    duns?: number;
    parentId: string;
    parentName: string;
    parentDeleted: boolean;
    primaryRole: CompanyPrimaryRoles;
    primaryRelationship: PrimaryRelationship;
    ultimateParentId: string;
    ultimateParentName: string;
    notes?: string;
    numberOfMappedMappings?: number;
    numberOfBrokers?: number;
    state?: string;
    /**
     * Empty array means that it's a root
     */
    path: string[] | [];
  }

  interface AmBestRawData {
    amBestNumber: number;
    naic?: number;

    ratingAlpha?: string;
    ratingModifier?: string;
    affiliationCode?: string;

    ratingNumeric?: number;

    financialSizeCategoryAlpha?: string;
    financialSizeCategoryNumeric?: number;

    ratingEffectiveDate?: Date;
    businessType?: string;

    responsibleRatingDivision?: string;
    state?: string;
    name: string;
    parentAlias?: string;
    parentName?: string;

    ultimateParentAmBestNumber?: number;
    ultimateParentName?: string;

    country?: string;

    ratingUnitNumber?: number;
    ratingUnitName?: string;
    ratingOutlook?: string;
    ratingImplication?: string;
    ratingAction?: string;
    ratingUnitIndicator?: string;

    federalEmployersId?: string;
    alienRegistrationNumber?: string;
  }

  type MappingInfo = Record<string, {name: string}[]>;

  interface Mappings {
    mappingInfo: MappingInfo;
  }

  type BrokersInfo = Record<string, {name: string}[]>;

  interface Brokers {
    tenantsInfo: BrokersInfo;
  }

  interface InsuranceCompanyDeleteResult {
    deleted: boolean;
    errorMessage?: string;
  }
}
