import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {paths} from '../../core/constants/paths';

const AppName = styled(Typography)({
  color: '#fff',
  textDecoration: 'none'
}) as typeof Typography;

export default function Header() {

  const pages = [
    {title: 'Companies', key: 'companies', to: paths.tree.get()},
    {title: 'Ops Dashboard', key: 'ops-dashboard', to: paths.ops_dashboard.get()}
  ];


  return (
    <AppBar position='static'>
      <Toolbar>
        <AppName variant='h5' component={Link} to='/'>
          Ennabl manage
        </AppName>

        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
          {pages.map((page) => (
            <Button
              key={page.key}
              href={page.to}
              sx={{my: 2, color: 'white', display: 'block'}}
            >
              {page.title}
            </Button>
          ))}
        </Box>

      </Toolbar>
    </AppBar>
  );
}
