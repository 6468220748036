import {GridColDef} from '@mui/x-data-grid-premium';
import {DataUnifierRow, Tenant, TenantData} from '../types';
import {
  dateFormatter,
  getDuration,
  parseDate,
  durationFormatter,
  setLicenseKey,
  taskStatuses,
  statusFormatter,
  renderWithProgress
} from '../utils';
import {useMemo} from 'react';
import {OpsDashboardTable} from './OpsDashboardTable';

setLicenseKey();

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
    valueGetter: (_, row) => row.id
  },
  {
    field: 'tenant_name',
    headerName: 'Tenant Name',
    width: 250,
    valueGetter: (_, row) => row.name
  },
  {
    field: 'system',
    headerName: 'External System',
    width: 200,
    valueGetter: (_, row) => row.externalSystem
  },
  {
    field: 'systemType',
    headerName: 'Type',
    width: 150,
    valueGetter: (_, row) => row.externalSystemType
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'singleSelect',
    valueOptions: taskStatuses,
    width: 80,
    align: 'center',
    display: 'flex',
    valueGetter: (_, row) => row.status,
    renderCell: renderWithProgress,
    valueFormatter: (value, row) => statusFormatter(value, row.errorMessage)
  },
  {
    field: 'duration',
    headerName: 'Duration',
    type: 'number',
    valueGetter: (_, row) => getDuration(row),
    valueFormatter: durationFormatter
  },
  {
    field: 'start_time',
    headerName: 'Start time',
    type: 'dateTime',
    width: 110,
    valueFormatter: dateFormatter,
    valueGetter: (_, row) => row.startTime
  },
  {
    field: 'end_time',
    headerName: 'End time',
    type: 'dateTime',
    width: 110,
    valueFormatter: dateFormatter,
    valueGetter: (_, row) => row.endTime
  },
  {
    field: 'inputPath',
    headerName: 'Input Path',
    width: 600,
    valueGetter: (_, row) => row.inputPath
  }
];

function transformToDataUnifierData(
  tenants: Tenant[],
  tenantsData: TenantData[]
): DataUnifierRow[] {
  return tenants
    .flatMap((tenant) => {
      const tenantInfo = tenantsData.find(([id]) => id === tenant.uid)?.[1];

      return (tenantInfo?.dataUnifierRunTasks || [])
        .map(task => {
          const {
            glueJobDetails,
            startTime,
            endTime,
            status,
            errorMessage
          } = task;
          const args = glueJobDetails?.args || {};
          const system = args['--EXTERNAL_SYSTEM_ID'] || '';
          const systemType = args['--EXTERNAL_SYSTEM_TYPE'] || '';
          const inputPath = args['--INPUT_PATH'] || '';

          return {
            uid: tenant.uid,
            name: tenant.name,
            startTime: parseDate(startTime),
            endTime: parseDate(endTime),
            status: status || undefined,
            externalSystem: system,
            externalSystemType: systemType,
            inputPath: inputPath,
            errorMessage: errorMessage || ''
          };
        });
    });
}

export default function DataUnifierTable({tenants, tenantsData, isLoading}: {
  tenants: Tenant[],
  tenantsData: TenantData[]
  isLoading: boolean
}) {
  const normalized = useMemo(() => transformToDataUnifierData(tenants, tenantsData), [tenants, tenantsData]);

  return (
    <OpsDashboardTable
      filterMode="client"
      sortingMode="client"
      paginationMode="client"
      rows={normalized}
      loading={isLoading}
      columns={columns}
    />
  );
}
