import React, {ReactChild} from 'react';
import {TrackJS} from 'trackjs';

class ErrorBoundary extends React.Component<{children: ReactChild}, {}> {
  constructor(props: any) {
    super(props);

    TrackJS.install({
      token: '0ef460a4553545adb2b9b97da763979a',
      application: 'ennabl-admin',
      enabled: process.env.NODE_ENV === 'production',
    });
  }

  componentDidCatch(error: Error) {
    TrackJS.track(error);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
