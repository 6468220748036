import {DataGridPremium, GridSlots, GridValidRowModel} from '@mui/x-data-grid-premium';
import {DataGridPremiumProps} from '@mui/x-data-grid-premium/models/dataGridPremiumProps';
import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

export const OpsDashboardTable = <R extends GridValidRowModel = any>(
  props: DataGridPremiumProps<R> & React.RefAttributes<HTMLDivElement>
) => {
  const tableProps: DataGridPremiumProps = {
    filterMode: 'client',
    sortingMode: 'client',
    paginationMode: 'client',
    ...props,
    initialState: {
      density: 'compact',
      columns: {columnVisibilityModel: {id: false}},
      pagination: {paginationModel: {pageSize: 0}},
      ...props.initialState
    },
    slots: {
      loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
      ...props.slots
    },
    getRowId: (row) => row.uid
  };
  return <DataGridPremium {...tableProps} />;
};
