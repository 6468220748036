import {GridColDef} from '@mui/x-data-grid-premium';
import {AgentRow, Tenant, TenantData} from '../types';
import {
  dateFormatter,
  getDuration,
  parseDate,
  durationFormatter,
  setLicenseKey,
  taskStatuses, statusFormatter, renderWithProgress
} from '../utils';
import {useMemo} from 'react';
import {OpsDashboardTable} from './OpsDashboardTable';

setLicenseKey();

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
    valueGetter: (_, row) => row.id
  },
  {
    field: 'tenant_name',
    headerName: 'Tenant Name',
    width: 250,
    valueGetter: (_, row) => row.name
  },
  {
    field: 'system',
    headerName: 'External System',
    width: 200,
    valueGetter: (_, row) => row.externalSystem
  },
  {
    field: 'systemType',
    headerName: 'Type',
    width: 150,
    valueGetter: (_, row) => row.externalSystemType
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'singleSelect',
    valueOptions: taskStatuses,
    width: 80,
    align: 'center',
    display: 'flex',
    renderCell: renderWithProgress,
    valueFormatter: (value, row) => statusFormatter(value, row.errorMessage),
    valueGetter: (_, row) => row.status,
  },
  {
    field: 'duration',
    headerName: 'Duration',
    type: 'number',
    valueGetter: (_, row) => getDuration(row),
    valueFormatter: durationFormatter
  },
  {
    field: 'start_time',
    headerName: 'Start time',
    type: 'dateTime',
    width: 110,
    valueFormatter: dateFormatter,
    valueGetter: (_, row) => row.startTime
  },
  {
    field: 'end_time',
    headerName: 'End time',
    type: 'dateTime',
    width: 110,
    valueFormatter: dateFormatter,
    valueGetter: (_, row) => row.endTime
  },
  {
    field: 'agentVersion',
    headerName: 'Version',
    width: 100,
    valueGetter: (_, row) => row.agentVersion
  },
  {
    field: 'extractionPath',
    headerName: 'Extraction Path',
    width: 700,
    valueGetter: (_, row) => row.extractionPath
  }
];

function transformToAgentStatusData(
  tenants: Tenant[],
  tenantsData: TenantData[]
): AgentRow[] {
  return tenants
    .flatMap((tenant) => {
      const tenantInfo = tenantsData.find(([id]) => id === tenant.uid)?.[1];

      return (tenantInfo?.agentStatuses || [])
        .map(task => {
          const {
            startTime,
            endTime,
            status,
            externalSystem,
            externalSystemType,
            extractionPath,
            agentVersion,
            errorMessage
          } = task;

          return {
            uid: tenant.uid,
            name: tenant.name,
            startTime: parseDate(startTime),
            endTime: parseDate(endTime),
            status: status || undefined,
            externalSystem: externalSystem || '',
            externalSystemType: externalSystemType || '',
            extractionPath: extractionPath || '',
            agentVersion: agentVersion || '',
            errorMessage: errorMessage || ''
          };
        });
    });
}

export default function AgentStatusTable({tenants, tenantsData, isLoading}: {
  tenants: Tenant[],
  tenantsData: TenantData[]
  isLoading: boolean
}) {
  const normalized = useMemo(() => transformToAgentStatusData(tenants, tenantsData), [tenants, tenantsData]);

  return <OpsDashboardTable rows={normalized} loading={isLoading} columns={columns} />;
}
