export const ENNABL_ENV = process.env.ENNABL_ENV || 'development';
export const API_HOST = process.env.ENNABL_API_HOST || 'https://api-v2.ennabl-test.com';
export const GRAPH_QL_DECISIONS_API_HOST =
  process.env.ENNABL_GRAPH_QL_DECISIONS_API_HOST || 'https://api-v2.ennabl-test.com/decisions';
export const GRAPH_QL_COMMAND_API_HOST =
  process.env.ENNABL_GRAPH_QL_COMMAND_API_HOST || 'https://api-v2.ennabl-test.com/command';
export const AUTH0_DOMAIN = process.env.ENNABL_AUTH0_DOMAIN || 'auth.ennabl-test.com';
export const AUTH0_CLIENT_ID = process.env.ENNABL_AUTH0_CLIENT_ID || '7iirboO9QKHDouuOiYpKSW4M4rpN6m0R';
export const AUTH0_API_AUDIENCE = process.env.ENNABL_AUTH0_API_AUDIENCE || 'https://api-v2.ennabl-test.com';
export const AUTH0_USER_INFO_AUDIENCE =
  process.env.ENNABL_AUTH0_USER_INFO_AUDIENCE || `https://${AUTH0_DOMAIN}/userinfo`;
export const APP_DEVICE_TRACK_URI = process.env.ENNABL_APP_DEVICE_TRACK_URI || 'https://api-v2.ennabl-test.com/preAuth';
export const APP_LOGIN_URI = process.env.ENNABL_APP_LOGIN_URI || 'https://login.ennabl-test.com';
