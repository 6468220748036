import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {identity, pickBy} from 'lodash/fp';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_HOST,
  // prepareHeaders: (headers, { getState }) => {
  //   // By default, if we have a token in the store, let's use that for authenticated requests
  //   const token = (getState() as RootState).auth.token;
  //   if (token) {
  //     headers.set('authentication', `Bearer ${token}`);
  //   }
  //   return headers;
  // },
});

export const companiesApi = createApi({
  reducerPath: 'companiesApi',
  baseQuery,
  tagTypes: ['Company'],
  endpoints: build => ({
    getCompanies: build.query<{companies: InsuranceCompany[]; totalPages: number}, void>({
      // clean undefined/null params
      query: ({offset, ...params}: any) => ({
        url: `companies?${new URLSearchParams(pickBy(identity, {...params, offset: +offset * +params.limit}))}`,
      }),
      extraOptions: {maxRetries: 6},
      providesTags: (results, error, arg) =>
        results
          ? [...results.companies.map(company => ({type: 'Company' as const, id: company.id})), 'Company']
          : ['Company'],
    }),
    addCompany: build.mutation<InsuranceCompany, Partial<InsuranceCompany>>({
      query: body => ({
        url: 'companies',
        method: 'POST',
        body,
      }),
      // Invalidates all Companies-type queries providing the `LIST` id - after all,
      // depending on the sort order, that newly created post could show up in any lists.
      invalidatesTags: [{type: 'Company', id: 'LIST'}],
    }),
    updateCompany: build.mutation<InsuranceCompany, Partial<InsuranceCompany>>({
      query(data) {
        const {id, ...body} = data;
        return {
          url: `companies/${id}`,
          method: 'PATCH',
          body,
        };
      },
      // Invalidates all queries that subscribe to this InsuranceCompany `id` only.
      // In this case, `getCompanies` will be re-run. `getCompanies` *might*  rerun,
      // if this id was under its results.
      invalidatesTags: (result, error, {id}) => [{type: 'Company', id}],
    }),
    deleteCompany: build.mutation<InsuranceCompanyDeleteResult, string>({
      query: id => {
        return {
          url: `companies/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => {
        if (result) {
          return [{type: 'Company', id}];
        }
        return [];
      },
    }),
    mappings: build.query<Mappings, string>({
      query: (id: string) => ({
        url: `mappings?ids=${id}`,
      }),
    }),
    brokers: build.query<Brokers, string>({
      query: (id: string) => ({
        url: `mappings/brokers?ids=${id}`,
      }),
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
  useAddCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
  useMappingsQuery,
  useBrokersQuery,
} = companiesApi;
