import {Routes, Route, Navigate} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import {paths} from 'core/constants/paths';
import Header from 'components/Header';
import RequireAuth from 'components/RequireAuth';
import {TreePage} from 'pages/TreePage/Loadable';
import {NotFoundPage} from 'pages/NotFoundPage/Loadable';
import {OpsDashboardPage} from '../pages/OpsDashboardPage';

function App() {
  return (
    <>
      <Header />

      <ToastContainer />

      <Routes>
        <Route path='/' element={<Navigate to={paths.tree.get()} />} />

        <Route
          path={paths.tree.toString()}
          element={
            <RequireAuth>
              <TreePage />
            </RequireAuth>
          }
        />

        <Route
          path={paths.ops_dashboard.toString()}
          element={
            <RequireAuth>
              <OpsDashboardPage />
            </RequireAuth>
          }
        />

        <Route path={paths.notFound.toString()} element={<NotFoundPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;
