type TreeParams = {
  offset?: number | string;
  limit?: number | string;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc' | undefined;
  /**
   * Search query
   */
  query?: string;
};

export const paths = {
  tree: {
    get: ({offset = 0, limit = 20, sortBy = 'id', sortOrder = 'asc', query}: TreeParams = {}): string =>
      `/tree/${offset}/${limit}/${sortBy}/${sortOrder}`,
    toString: (): string => '/tree/:offset/:limit/:sortBy/:sortOrder'
  },
  ops_dashboard: {
    get: (): string =>
      `/ops-dashboard`,
    toString: (): string => '/ops-dashboard'
  },
  notFound: {
    get: (): string => '/404',
    toString: (): string => '/404'
  }
};
