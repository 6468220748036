import React, {useContext, useState} from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {DialogActions, DialogContent, DialogContentText} from '@mui/material';

type UseDialogShowReturnType = {
  show: boolean;
  setShow: (value: boolean) => void;
  onHide: () => void;
};

const useModalShow = (): UseDialogShowReturnType => {
  const [show, setShow] = useState(false);

  const handleOnHide = () => {
    setShow(false);
  };

  return {
    show,
    setShow,
    onHide: handleOnHide,
  };
};

type ModalContextType = {
  showMessage: (title: string, content: string | JSX.Element) => void;
};

type ShowMessageDialogContextProviderProps = {
  children: React.ReactNode;
};

const ShowMessageDialogContext = React.createContext<ModalContextType>({} as ModalContextType);

const MessageDialogContextProvider: React.FC<ShowMessageDialogContextProviderProps> = props => {
  const {setShow, show, onHide} = useModalShow();
  const [content, setContent] = useState<{title: string; message: string | JSX.Element} | null>();

  const handleShow = (title: string, message: string | JSX.Element) => {
    setContent({
      title,
      message,
    });
    setShow(true);
  };

  const modalContext: ModalContextType = {
    showMessage: handleShow,
  };

  return (
    <ShowMessageDialogContext.Provider value={modalContext}>
      {props.children}

      {content && (
        <Dialog open={show}>
          <DialogTitle>{content.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>{content.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onHide}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </ShowMessageDialogContext.Provider>
  );
};

const useShowMessageDialogContext = (): ModalContextType => useContext(ShowMessageDialogContext);

export {useModalShow, useShowMessageDialogContext};

export default MessageDialogContextProvider;
