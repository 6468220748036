import {createSelector} from '@reduxjs/toolkit';

import {RootState} from 'core/types';

import {initialState} from '.';

const selectDomain = (state: RootState) => state?.auth || initialState;

export const selectAuthToken = createSelector([selectDomain], authState => authState.token);

export const selectPermissions = createSelector([selectDomain], authState => authState.permissions);

export const selectUser = createSelector([selectDomain], authState => authState.user);

export const selectUserLoading = createSelector([selectDomain], authState => authState.loading);
