import {GridColDef} from '@mui/x-data-grid-premium';
import {FilesConverterRow, Tenant, TenantData} from '../types';
import {
  dateFormatter,
  getDuration,
  parseDate,
  durationFormatter,
  statusFormatter,
  setLicenseKey,
  taskStatuses, renderWithProgress
} from '../utils';
import {useMemo} from 'react';
import {OpsDashboardTable} from './OpsDashboardTable';

setLicenseKey();

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
    valueGetter: (_, row) => row.id
  },
  {
    field: 'tenant_name',
    headerName: 'Tenant Name',
    width: 300,
    valueGetter: (_, row) => row.name
  },
  {
    field: 'key',
    headerName: `File's key`,
    width: 300,
    valueGetter: (_, row) => row.sourceFileKey
  },
  {
    field: 'bucket',
    headerName: `File's bucket`,
    width: 300,
    valueGetter: (_, row) => row.sourceFileBucket
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'singleSelect',
    valueOptions: taskStatuses,
    width: 80,
    renderCell: renderWithProgress,
    align: 'center',
    display: 'flex',
    valueGetter: (_, row) => row.status,
    valueFormatter: (value, row) => statusFormatter(value, row.errorMessage)
  },
  {
    field: 'duration',
    headerName: 'Duration',
    type: 'number',
    valueGetter: (_, row) => getDuration(row),
    valueFormatter: durationFormatter
  },
  {
    field: 'start_time',
    headerName: 'Start time',
    type: 'dateTime',
    width: 110,
    valueFormatter: dateFormatter,
    valueGetter: (_, row) => row.startTime
  },
  {
    field: 'end_time',
    headerName: 'End time',
    type: 'dateTime',
    width: 110,
    valueFormatter: dateFormatter,
    valueGetter: (_, row) => row.endTime
  }
];

function transformToFileConverterData(
  tenants: Tenant[],
  tenantsData: TenantData[]
): FilesConverterRow[] {
  return tenants
    .flatMap((tenant) => {
      const tenantInfo = tenantsData.find(([id]) => id === tenant.uid)?.[1];

      return (tenantInfo?.fileConverterRunTasks || [])
        .map(task => {
          const attrNameToValue = new Map<string, string>();
          const containerOverrides = task.ecsDetails?.containerOverrides || [];

          for (const elem of containerOverrides) {
            attrNameToValue.set(elem['Name'], elem['Value']);
          }

          const bucket = attrNameToValue.get('BUCKET') || '';
          const key = attrNameToValue.get('KEY') || '';
          const tenantUidAndSystem = key.split('/')[0] || '';

          return {
            uid: tenantUidAndSystem,
            name: tenant.name,
            startTime: parseDate(task.startTime),
            endTime: parseDate(task.endTime),
            status: task.status,
            sourceFileKey: key,
            sourceFileBucket: bucket
          };
        });
    });
}

export default function FilesConverterTable({tenants, tenantsData, isLoading}: {
  tenants: Tenant[],
  tenantsData: TenantData[]
  isLoading: boolean
}) {
  const normalized = useMemo(() => transformToFileConverterData(tenants, tenantsData), [tenants, tenantsData]);

  return <OpsDashboardTable rows={normalized} loading={isLoading} columns={columns} />;
}
