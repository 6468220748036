import reduxFreeze from 'redux-freeze';
import {configureStore, Middleware} from '@reduxjs/toolkit';
import {Store, StoreEnhancer} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {createInjectorsEnhancer} from 'redux-injectors';
import {toast} from 'react-toastify';

import {createReducer} from 'core/reducers';
import trackJSMiddleware from 'core/trackJSMiddleware';

import {companiesApi} from 'modules/system/services/companies';
import {tenantsApi} from '../modules/system/services/ops_dasboard';

let store: Store | undefined;

export function initStore() {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error, errorInfo) => {
      console.error('Saga error', error, errorInfo);
      toast.error(`Error: ${error.message}`);
    },
  });
  const {run: runSaga} = sagaMiddleware;

  const middlewares = [
    sagaMiddleware,
    companiesApi.middleware,
    tenantsApi.middleware,
    process.env.NODE_ENV === 'development' && reduxFreeze,
    process.env.NODE_ENV === 'production' && trackJSMiddleware,
  ].filter(Boolean) as Middleware[];

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer[];

  store = configureStore({
    reducer: createReducer(),
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(...middlewares),
    devTools: true,
    enhancers,
  });

  return store;
}

export const getStore = () => store;
