import {TrackJS} from 'trackjs';
import {Action, Store} from 'redux';

import {updateUserSuccess} from 'modules/system/actions/SystemActions';

const IGNORED_ACTION_TYPES = [updateUserSuccess.toString()];

const TrackJSMiddleware = (store: Store) => (next: any) => (action: Action) => {
  try {
    if (!IGNORED_ACTION_TYPES.includes(action.type)) {
      // log every action except ignored so they appear in the TrackJS telemetry timeline
      TrackJS.console.log(action);
    }

    return next(action);
  } catch (err) {
    console.warn(store.getState());

    TrackJS.track(err as Error);
  }
};

export default TrackJSMiddleware;
