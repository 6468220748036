import dayjs from 'dayjs';
import {GridRenderCellParams} from '@mui/x-data-grid/models/params/gridCellParams';
import {ProcessRun} from './types';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import Duration from 'dayjs/plugin/duration';
import RelativeTime from 'dayjs/plugin/relativeTime';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import {useShowMessageDialogContext} from './components/MessageDialogContext';
import {GridAggregationFunction} from '@mui/x-data-grid-premium';
import {LicenseInfo} from '@mui/x-license';
import {LinearProgress} from '@mui/material';

dayjs.extend(Duration);
dayjs.extend(RelativeTime);

export function parseDate(date: string | Date | number | undefined): Date | undefined {
  switch (typeof date) {
    case 'string': {
      return new Date(date);
    }
    case 'undefined': {
      return undefined;
    }
    case 'number': {
      return dayjs(date).toDate();
    }
    default: {
      return date;
    }
  }
}

export function renderWithProgress(params: GridRenderCellParams): React.ReactNode {
  return params.row.loading ? <LinearProgress /> : params.formattedValue;
}

export function dateFormatter(value: string | number | Date | null | undefined) {
  return value ? dayjs(value)?.format('MMM D, HH:mm') : undefined;
}

export function durationFormatter(value: string | undefined) {
  return value ? dayjs.duration(value)?.humanize() : undefined;
}

export function statusFormatter(value: string, errorMessage: string | undefined = undefined) {
  switch (value) {
    case undefined: {
      return <></>;
    }
    case 'SUCCEED': {
      return <DoneIcon color="success" />;
    }
    case 'FAILED': {
      return <FailedStatusButton message={errorMessage} />;
    }
    case 'RUNNING': {
      return <PendingIcon color="action" />;
    }
  }
}

export function percentFormatter(value: number | undefined) {
  return value ? (value * 100).toFixed(2) + '%' : undefined;
}

export function moneyFormatter(value: number | undefined) {
  if (value === undefined) return undefined;

  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
}

export function getDuration(data: ProcessRun) {
  if (data?.startTime === undefined) return undefined;
  const start = dayjs(parseDate(data?.startTime));
  const end = dayjs(parseDate(data?.endTime));

  return end.diff(start);
}

export function getPct(actual: number | undefined, total: number | undefined): number | undefined {
  if (total !== undefined && total > 0 && actual !== undefined) {
    return actual / total;
  }
  return undefined;
}

export const successRateAgg: GridAggregationFunction<{total: number; success: number}, number> = {
  label: '',
  getCellValue: ({row}) => {
    const successRate = row.successRate || {total: 0, success: 0};
    return {total: successRate.total || 0, success: successRate.success || 0};
  },
  apply: ({values}) => {
    let total = 0;
    let success = 0;
    values.forEach(value => {
      if (value) {
        total += value.total;
        success += value.success;
      }
    });
    if (total !== 0) return success / total;
    else return 0;
  },
  columnTypes: ['number']
};

export const parquetRateAgg: GridAggregationFunction<{total: number; success: number}, number> = {
  label: '',
  getCellValue: ({row}) => {
    const parquetRate = row.parquetRate || {total: 0, success: 0};
    return {total: parquetRate.total || 0, success: parquetRate.success || 0};
  },
  apply: ({values}) => {
    let total = 0;
    let success = 0;
    values.forEach(value => {
      if (value) {
        total += value.total;
        success += value.success;
      }
    });
    if (total !== 0) return success / total;
    else return 0;
  },
  columnTypes: ['number']
};

export const etlGlueCostAgg: GridAggregationFunction<{cost: number}, number> = {
  label: '',
  getCellValue: ({row}) => ({cost: row.glueCost || 0}),
  apply: ({values}) => {
    let total = 0;
    values.forEach(value => {
      if (value) {
        total += value.cost;
      }
    });
    return total;
  },
  columnTypes: ['number']
};

function FailedStatusButton({message}: {message: string | undefined}) {
  const modalContext = useShowMessageDialogContext();

  const showMessage = (content: string) => {
    modalContext.showMessage('Error Info', content);
  };

  if (message === undefined) {
    return <ErrorIcon color="error" />;
  } else
    return (
      <IconButton color="error" onClick={() => showMessage(message)}>
        <ErrorIcon color="error" />
      </IconButton>
    );
}

export const taskStatuses = [
  {value: 'SUCCEED', label: 'SUCCEED'},
  {value: 'FAILED', label: 'FAILED'},
  {value: 'RUNNING', label: 'RUNNING'}
];

export function setLicenseKey() {
  const MUI_PRO_LICENCE_KEY =
    '735235be3ab05940937e40c17dd05416T1JERVI6NDI1NjEsRVhQSVJZPTE2ODI2MDI3MzAwMDAsS0VZVkVSU0lPTj0x';
  LicenseInfo.setLicenseKey(MUI_PRO_LICENCE_KEY);
}
