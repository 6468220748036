import {createModuleAction} from 'utils/reduxUtils';

import {TokenData} from 'utils/authUtils';

const createAction = createModuleAction('SYSTEM');

interface UpdateUserDetails {
  token: string;
  tokenData: TokenData;
  user: Auth0UserProfileExtended;
}

export const updateUserRequest = createAction<Auth0UserProfileExtended>('UPDATE_USER_REQUEST');

export const updateUserSuccess = createAction<UpdateUserDetails>('UPDATE_USER_SUCCESS');

export const updateUserFailure = createAction('UPDATE_USER_FAILURE');
