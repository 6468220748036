import {createRoot} from 'react-dom/client';

import {initStore} from 'core/store';
import Root from 'core/Root';
// import reportWebVitals from './reportWebVitals';

const store = initStore();

const root = createRoot(document.getElementById('root')!);
root.render(<Root store={store} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
