import {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import FullEtlTable from './components/FullEtlTable';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import Box from '@mui/material/Box';
import {Tab} from '@mui/material';
import DataUnifierTable from './components/DataUnifierTable';
import FilesConverterTable from './components/SourceFilesConverterTable';
import {Tenant, TenantData} from './types';
import DataQualityTable from './components/DataQualityTable';
import MessageDialogContextProvider from './components/MessageDialogContext';
import {useLazyGetTenantInfoQuery, useLazyGetTenantListQuery} from '../../modules/system/services/ops_dasboard';
import AgentStatusTable from './components/AgentStatusTable';

const Main = styled('div')(() => ({
  maxWidth: 1920,
  height: 'calc(100vh - 120px)',
  display: 'flex',
  flexDirection: 'column'
}));

export function OpsDashboardPage() {
  const [currentSection, setCurrentSection] = useState('full-etl');
  const [offset, setOffset] = useState(0);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [tenantsData, setTenantData] = useState<TenantData[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [getNextTenants, {data: nextTenants = []}] = useLazyGetTenantListQuery();
  const [getTenantInfo] = useLazyGetTenantInfoQuery();

  const batchSize = 100;

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getNextTenants({limit: batchSize, offset: offset}).unwrap();
      setLoading(false);
    })();
  }, [offset]);

  useEffect(() => {

    const fetchAndSetTenantData = (tenant: Tenant) => {
      getTenantInfo({tenantId: tenant.uid})
        .unwrap()
        .then((tenantInfo) => {
          setTenantData((prevTenantData) => [...prevTenantData, [tenant.uid, tenantInfo] as TenantData]);
        })
        .catch(() => {
          setTenantData((prevTenantData) => [...prevTenantData, [tenant.uid, null] as TenantData]);
        });
    };

    if (nextTenants.length > 0) {
      setTenants(tenants.concat(nextTenants));
      if (nextTenants.length === batchSize) {
        setOffset(offset + batchSize);
      }

      nextTenants.forEach((tenant) => {
        fetchAndSetTenantData(tenant);
      });
    }
  }, [nextTenants]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentSection(newValue);
  };

  return (
    <MessageDialogContextProvider>
      <TabContext value={currentSection}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <TabList onChange={handleChange}>
            <Tab label="Full ETL" value="full-etl" />
            <Tab label="Data Unifier" value="data-unifier" />
            <Tab label="Agents" value="agents" />
            <Tab label="Files Converter" value="files-converter" />
            <Tab label="Data Quality" value="data-quality" />
          </TabList>
        </Box>
        <TabPanel value="full-etl" sx={{p: 0}}>
          <Main>
            <FullEtlTable tenants={tenants} tenantsData={tenantsData} isLoading={isLoading} />
          </Main>
        </TabPanel>
        <TabPanel value="data-unifier" sx={{p: 0}}>
          <Main>
            <DataUnifierTable tenants={tenants} tenantsData={tenantsData} isLoading={isLoading} />
          </Main>
        </TabPanel>
        <TabPanel value="agents" sx={{p: 0}}>
          <Main>
            <AgentStatusTable tenants={tenants} tenantsData={tenantsData} isLoading={isLoading} />
          </Main>
        </TabPanel>
        <TabPanel value="files-converter" sx={{p: 0}}>
          <Main>
            <FilesConverterTable tenants={tenants} tenantsData={tenantsData} isLoading={isLoading} />
          </Main>
        </TabPanel>
        <TabPanel value="data-quality" sx={{p: 0}}>
          <Main>
            <DataQualityTable tenants={tenants} tenantsData={tenantsData} isLoading={isLoading} />
          </Main>
        </TabPanel>
      </TabContext>
    </MessageDialogContextProvider>
  );
}