import {isAnyOf} from '@reduxjs/toolkit';

import {createSlice, useInjectReducer, useInjectSaga} from 'utils/reduxUtils';
import {updateUserRequest, updateUserSuccess, updateUserFailure} from 'modules/system/actions/SystemActions';

import type {AuthState} from './types';

import systemIndexSaga from './saga';

export const initialState: AuthState = {
  loading: false,
  token: null,
  permissions: [],
  user: null,
  impersonateTenantName: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(updateUserSuccess, (state, {payload}) => ({
      ...state,
      token: payload!.token || state.token,
      permissions: payload!.tokenData.permissions,
      user: payload!.user ? {...(state.user || {}), ...payload!.user} : state.user,

      impersonateTenantName: payload!.tokenData.impersonateTenantName,
    }));

    builder.addCase(updateUserRequest, state => ({
      ...state,
      loading: true,
    }));

    builder.addMatcher(isAnyOf(updateUserSuccess, updateUserFailure), state => ({
      ...state,
      loading: false,
    }));
  },
});

export const {reducer} = slice;

export const useAuthSlice = () => {
  useInjectReducer({key: slice.name, reducer: slice.reducer});
  useInjectSaga({key: slice.name, saga: systemIndexSaga});
};
