import {useCallback, useMemo} from 'react';
import jwtDecode from 'jwt-decode';
import * as auth0 from 'auth0-js';
import {isEqual} from 'lodash/fp';
import {useSelector} from 'react-redux';
import {nanoid} from '@reduxjs/toolkit';

import type {UserPermission} from 'types';

import {AUTH0_CLIENT_ID, APP_LOGIN_URI, AUTH0_DOMAIN, AUTH0_API_AUDIENCE} from 'config';
import {selectPermissions, selectUser, selectUserLoading} from 'modules/system/slice/selectors';

const state = nanoid();
const nonce = nanoid();

const redirectUri = encodeURI(window.location.href);

const loginUri = `${APP_LOGIN_URI}?state=${state}&nonce=${nonce}&client_id=${AUTH0_CLIENT_ID}&redirectUri=${redirectUri}`;
const logoutUri = `${APP_LOGIN_URI}?logout=true&redirectUri=${redirectUri}`;
const ennablNamespace = 'https://ennabl.com';

const auth0Client = new auth0.WebAuth({
  clientID: AUTH0_CLIENT_ID,
  domain: AUTH0_DOMAIN,
  redirectUri: window.location.origin,
  responseType: 'code',
  state,
  audience: AUTH0_API_AUDIENCE,
  scope: 'openid profile email',
});

export interface TokenData {
  impersonateTenantId: string;
  impersonateTenantName: string;
  impersonateUser: string;
  permissions: UserPermission[];
}

/**
 * References last issued token data.
 */
let tokenData: TokenData | undefined = undefined;

async function requestAccessToken(): Promise<{
  token: string;
  tokenData: TokenData;
  expiresInSec: number;
  user: auth0.Auth0UserProfile;
}> {
  // Request access token
  const {token, decodedToken, expiresInSec} = await getToken();

  // Get user info
  const user = await new Promise<auth0.Auth0UserProfile>((resolve, reject) => {
    auth0Client.client.userInfo(token, function (err, user) {
      if (err) {
        reject(err);
      } else {
        resolve(user);
      }
    });
  });

  const newTokenData = {
    impersonateTenantId: decodedToken[`${ennablNamespace}/impersonateTenantId`],
    impersonateTenantName: decodedToken[`${ennablNamespace}/impersonateTenantName`],
    impersonateUser: decodedToken[`${ennablNamespace}/impersonateUser`],
    permissions: decodedToken.permissions || [],
  };

  if (tokenData && !isEqual(tokenData, newTokenData)) {
    // Token data changed, we must refresh page to make sure actual data is shown on the UI
    // An example: an ennabl admin impersonated a new user, info shown on the UI right now might be inaccurate.
    window.location.reload();
    return {token, tokenData, user, expiresInSec};
  }

  tokenData = newTokenData;

  return {token, tokenData: newTokenData, user, expiresInSec};
}

function logout() {
  window.location.replace(logoutUri);
}

async function getToken() {
  return await new Promise<{token: string; decodedToken: any; expiresInSec: number}>(resolve => {
    auth0Client.checkSession({responseType: 'token'}, (error, result) => {
      if (error) {
        if (error.code === 'consent_required') {
          auth0Client.authorize({});
        } else {
          redirectToLogin();
        }
        return;
      }

      const token = result.accessToken!;
      const decodedToken = jwtDecode<Record<string, any>>(token);
      const isFullyAuthenticated = decodedToken[`https://ennabl.com/authType`] === 'FULL';

      if (!isFullyAuthenticated) {
        redirectToLogin();
        return;
      }

      return resolve({
        token,
        decodedToken,
        expiresInSec: result.expiresIn!,
      });
    });
  });
}

function redirectToLogin() {
  window.location.replace(loginUri);
}

export const useAuth = () => {
  const permissions = useSelector(selectPermissions);

  const hasPermission = useCallback((permission: UserPermission) => permissions.includes(permission), [permissions]);

  return {
    isAuthenticated: useSelector(selectUser) !== null,
    permissions,
    hasPermission,
    requestAccessToken: requestAccessToken,
    user: useSelector(selectUser),
    loading: useSelector(selectUserLoading),
    logout: logout,
  };
};

export const usePermission = (permission: UserPermission) => {
  const permissions = useSelector(selectPermissions);
  return useMemo(() => permissions.includes(permission), [permissions, permission]);
};
