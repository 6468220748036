import {useDispatch, useSelector} from 'react-redux';
import useAsyncEffect from 'use-async-effect';
import CircularProgress from '@mui/material/CircularProgress';

import {updateUserSuccess} from 'modules/system/actions/SystemActions';
import {useAuthSlice} from 'modules/system/slice';
import {selectAuthToken} from 'modules/system/slice/selectors';
import {useAuth} from 'utils/authUtils';

const AuthorizationInProgress = () => (
  <div
    style={{
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <CircularProgress />
  </div>
);

const AuthorizedArea = ({children}: {children: JSX.Element}) => {
  useAuthSlice();
  const {requestAccessToken} = useAuth();
  const dispatch = useDispatch();
  // TODO: Store token loading state in state?
  const token = useSelector(selectAuthToken);

  useAsyncEffect(
    async isMounted => {
      const requestAndDispatch = async () => {
        const {token, tokenData, user, expiresInSec} = await requestAccessToken();

        // This should never be unmounted but just in case
        if (!isMounted()) {
          return;
        }

        dispatch(updateUserSuccess({token, tokenData, user}));

        // Request new access token after token timeout / 2
        setTimeout(requestAndDispatch, (expiresInSec * 1000) / 2);
      };

      await requestAndDispatch();
    },
    [requestAccessToken]
  );

  // Do not render children until token is obtained.
  // Otherwise GraphQL will fire requests without a token.
  return token ? children : <AuthorizationInProgress />;
};

export default AuthorizedArea;
