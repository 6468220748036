import {getPct, percentFormatter, renderWithProgress, setLicenseKey} from '../utils';
import {GridColDef, useGridApiRef} from '@mui/x-data-grid-premium';
import {DataQualityRow, Tenant, TenantData} from '../types';
import React, {useMemo, useState} from 'react';
import {ToggleButton, ToggleButtonGroup} from '@mui/material';
import {OpsDashboardTable} from './OpsDashboardTable';

setLicenseKey();

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
    valueGetter: (_, row) => row.id
  },
  {
    field: 'tenant_name',
    headerName: 'Tenant Name',
    width: 250,
    valueGetter: (_, row) => row.name
  },
  {
    field: 'system',
    headerName: 'External System',
    width: 200,
    valueGetter: (_, row) => row.externalSystem
  },
  {
    field: 'entity',
    headerName: 'Entity',
    width: 250,
    valueGetter: (_, row) => row.entity
  },
  {
    field: 'messageType',
    headerName: 'Type',
    type: 'singleSelect',
    valueOptions: [
      {value: 'WARNING', label: 'WARNING'},
      {value: 'SKIP', label: 'SKIP'}
    ],
    width: 100,
    valueGetter: (_, row) => row.messageType
  },
  {
    field: 'code',
    headerName: 'Message Code',
    width: 500,
    valueGetter: (_, row) => row.code
  },
  {
    field: 'messageCnt',
    headerName: 'Message Cnt',
    type: 'number',
    valueGetter: (_, row) => row.messageCnt
  },
  {
    field: 'totalCnt',
    headerName: 'Total Records',
    type: 'number',
    valueGetter: (_, row) => row.totalCnt
  },
  {
    field: 'messagePct',
    headerName: 'Message %',
    type: 'number',
    renderCell: renderWithProgress,
    valueGetter: (_, row) => getPct(row.messageCnt, row.totalCnt),
    valueFormatter: percentFormatter
  }
];

function transformToDataQualityData(
  tenants: Tenant[],
  tenantsData: TenantData[]
): DataQualityRow[] {
  return tenants
    .flatMap((tenant) => {
      const tenantInfo = tenantsData.find(([id]) => id === tenant.uid)?.[1];

      return (tenantInfo?.etlMessages || [])
        .map(message => ({
          uid: `${tenant.uid}/${message.externalSystem}/${message.entity}/${message.messageType}/${message.code}`,
          name: tenant.name,
          entity: message.entity,
          messageType: message.messageType,
          code: message.code,
          externalSystem: message.externalSystem,
          messageCnt: message.messageCnt,
          totalCnt: message.totalCnt
        }));
    });
}

export default function DataQualityTable({tenants, tenantsData, isLoading}: {
  tenants: Tenant[],
  tenantsData: TenantData[]
  isLoading: boolean
}) {
  {
    const normalized = useMemo(() => transformToDataQualityData(tenants, tenantsData), [tenants, tenantsData]);

    const apiRef = useGridApiRef();

    type PredefinedFilter = 'all' | 'product' | 'company';

    const [predefinedFilter, setPredefinedFilter] = useState<PredefinedFilter>('all');

    const handlePreFilter = (event: React.MouseEvent<HTMLElement>, newPredefinedFilter: PredefinedFilter) => {
      setPredefinedFilter(newPredefinedFilter);
      switch (newPredefinedFilter) {
        case 'all': {
          apiRef.current.setFilterModel({items: []});
          break;
        }
        case 'product': {
          apiRef.current.setFilterModel({
            items: [
              {field: 'entity', value: 'RawProduct', operator: 'equals'},
              {field: 'code', value: 'PRODUCT_IS_NOT_MAPPED', operator: 'equals'}
            ]
          });
          break;
        }
        case 'company': {
          apiRef.current.setFilterModel({
            items: [
              {field: 'entity', value: 'RawCompany', operator: 'equals'},
              {field: 'code', value: 'COMPANY_IS_NOT_MAPPED', operator: 'equals'}
            ]
          });
          break;
        }
      }
    };

    return (
      <>
        <ToggleButtonGroup
          size="small"
          value={predefinedFilter}
          exclusive
          onChange={handlePreFilter}
          aria-label="text alignment"
        >
          <ToggleButton value="all">All Issues</ToggleButton>
          <ToggleButton value="product">Product Mapping Issues</ToggleButton>
          <ToggleButton value="company">Company Mapping Issues</ToggleButton>
        </ToggleButtonGroup>
        <OpsDashboardTable apiRef={apiRef} rows={normalized} loading={isLoading} columns={columns} />
      </>
    );
  }
}
