import {combineReducers} from 'redux';

import type {InjectedReducersType} from 'utils/types/injectorTypings';

import {companiesApi} from 'modules/system/services/companies';
import {tenantsApi} from 'modules/system/services/ops_dasboard';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer<I extends InjectedReducersType>(injectedReducers: I = {} as I) {
  // Initially we don't have any injectedReducers, so returning identity
  // function to avoid the error
  if (Object.keys(injectedReducers).length === 0) {
    return (state: any) => state;
  } else {
    return combineReducers<I>({
      ...injectedReducers,
      [companiesApi.reducerPath]: companiesApi.reducer,
      [tenantsApi.reducerPath]: tenantsApi.reducer,
    });
  }
}
