import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import ErrorBoundary from 'core/ErrorBoundary';
import AuthorizedArea from 'components/AuthorizedArea';
import App from './App';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

const Root = ({store}: {store: any}) => (
  <ErrorBoundary>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Provider store={store}>
        <BrowserRouter>
          <React.StrictMode>
            <CssBaseline />

            <AuthorizedArea>
              <App />
            </AuthorizedArea>
          </React.StrictMode>
        </BrowserRouter>
      </Provider>
    </LocalizationProvider>
  </ErrorBoundary>
);

export default Root;
