import {GridColDef, GridColumnGroupingModel} from '@mui/x-data-grid-premium';
import {FullEtlRow, Tenant, TenantData} from '../types';
import GroupingHeader from './GroupingHeader';
import {
  dateFormatter,
  durationFormatter,
  getDuration,
  getPct,
  parseDate,
  statusFormatter,
  setLicenseKey,
  successRateAgg,
  parquetRateAgg,
  etlGlueCostAgg,
  taskStatuses,
  renderWithProgress,
  percentFormatter, moneyFormatter
} from '../utils';
import {useMemo} from 'react';
import {OpsDashboardTable} from './OpsDashboardTable';

setLicenseKey();

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
    valueGetter: (_, row) => row.id
  },
  {
    field: 'tenant_name',
    headerName: 'Name',
    width: 250,
    valueGetter: (_, row) => row.name
  },
  {
    field: 'etl_start_time',
    headerName: 'Start time',
    type: 'dateTime',
    width: 110,
    renderCell: renderWithProgress,
    valueFormatter: dateFormatter,
    valueGetter: (_, row) => row.startTime
  },
  {
    field: 'etl_end_time',
    headerName: 'End time',
    type: 'dateTime',
    width: 110,
    renderCell: renderWithProgress,
    valueFormatter: dateFormatter,
    valueGetter: (_, row) => row.endTime
  },
  {
    field: 'etl_duration',
    headerName: 'Duration',
    type: 'number',
    renderCell: renderWithProgress,
    valueFormatter: durationFormatter,
    valueGetter: (_, row) => getDuration(row)
  },
  {
    field: 'etl_status',
    headerName: 'Status',
    type: 'singleSelect',
    valueOptions: taskStatuses,
    width: 80,
    display: 'flex',
    align: 'center',
    valueGetter: (_, row) => row.status,
    renderCell: renderWithProgress,
    valueFormatter: (value, row) => statusFormatter(value, row.errorMessage)
  },
  {
    field: 'etl_success_rate',
    headerName: 'Success rate',
    type: 'number',
    renderCell: renderWithProgress,
    valueGetter: (_, row) => getPct(row.successRate?.success, row.successRate?.total),
    valueFormatter: percentFormatter
  },
  {
    field: 'etl_parquet_rate',
    headerName: 'Parquet rate',
    type: 'number',
    renderCell: renderWithProgress,
    valueGetter: (_, row) => getPct(row.parquetRate?.success, row.parquetRate?.total),
    valueFormatter: percentFormatter
  },
  {
    field: 'etl_glue_cost',
    headerName: 'Glue Cost',
    type: 'number',
    renderCell: renderWithProgress,
    valueGetter: (_, row) => row.glueCost,
    valueFormatter: moneyFormatter
  },
  {
    field: 'du_duration',
    headerName: 'Duration',
    type: 'number',
    renderCell: renderWithProgress,
    valueGetter: (_, row) => getDuration(row.dataUnifier),
    valueFormatter: durationFormatter
  },
  {
    field: 'du_status',
    headerName: 'Status',
    type: 'singleSelect',
    valueOptions: taskStatuses,
    width: 80,
    align: 'center',
    display: 'flex',
    valueGetter: (_, row) => row.dataUnifier?.status,
    renderCell: renderWithProgress,
    valueFormatter: (value, row) => statusFormatter(value, row.dataUnifier?.errorMessage)
  },
  {
    field: 'du_start_time',
    headerName: 'Start time',
    type: 'dateTime',
    width: 110,
    renderCell: renderWithProgress,
    valueFormatter: dateFormatter,
    valueGetter: (_, row) => row.dataUnifier?.startTime
  },
  {
    field: 'du_end_time',
    headerName: 'End time',
    type: 'dateTime',
    width: 110,
    renderCell: renderWithProgress,
    valueFormatter: dateFormatter,
    valueGetter: (_, row) => row.dataUnifier?.endTime
  },

  {
    field: 'de_duration',
    headerName: 'Duration',
    type: 'number',
    renderCell: renderWithProgress,
    valueGetter: (_, row) => getDuration(row.dataEnrichment),
    valueFormatter: durationFormatter
  },
  {
    field: 'de_status',
    headerName: 'Status',
    type: 'singleSelect',
    valueOptions: taskStatuses,
    width: 80,
    align: 'center',
    display: 'flex',
    renderCell: renderWithProgress,
    valueGetter: (_, row) => row.dataEnrichment?.status,
    valueFormatter: (value, row) => statusFormatter(value, row.dataEnrichment?.errorMessage)
  },
  {
    field: 'de_start_time',
    headerName: 'Start time',
    type: 'dateTime',
    width: 110,
    renderCell: renderWithProgress,
    valueFormatter: dateFormatter,
    valueGetter: (_, row) => row.dataEnrichment?.startTime
  },
  {
    field: 'de_end_time',
    headerName: 'End time',
    type: 'dateTime',
    width: 110,
    renderCell: renderWithProgress,
    valueFormatter: dateFormatter,
    valueGetter: (_, row) => row.dataEnrichment?.endTime
  },

  {
    field: 'dt_duration',
    headerName: 'Duration',
    type: 'number',
    renderCell: renderWithProgress,
    valueGetter: (_, row) => getDuration(row.dataTailor),
    valueFormatter: durationFormatter
  },
  {
    field: 'dt_status',
    headerName: 'Status',
    type: 'singleSelect',
    valueOptions: taskStatuses,
    width: 80,
    align: 'center',
    display: 'flex',
    renderCell: renderWithProgress,
    valueGetter: (_, row) => row.dataTailor?.status,
    valueFormatter: (value, row) => statusFormatter(value, row.dataTailor?.errorMessage)
  },
  {
    field: 'dt_start_time',
    headerName: 'Start time',
    type: 'dateTime',
    width: 110,
    renderCell: renderWithProgress,
    valueFormatter: dateFormatter,
    valueGetter: (_, row) => row.dataTailor?.startTime
  },
  {
    field: 'dt_end_time',
    headerName: 'End time',
    type: 'dateTime',
    width: 110,
    renderCell: renderWithProgress,
    valueFormatter: dateFormatter,
    valueGetter: (_, row) => row.dataTailor?.endTime
  },

  {
    field: 'ds_duration',
    headerName: 'Duration',
    type: 'number',
    renderCell: renderWithProgress,
    valueGetter: (_, row) => getDuration(row.dataSink),
    valueFormatter: durationFormatter
  },
  {
    field: 'ds_status',
    headerName: 'Status',
    type: 'singleSelect',
    valueOptions: taskStatuses,
    width: 80,
    align: 'center',
    display: 'flex',
    renderCell: renderWithProgress,
    valueGetter: (_, row) => row.dataSink?.status,
    valueFormatter: (value, row) => statusFormatter(value, row.dataSink?.errorMessage)
  },
  {
    field: 'ds_start_time',
    headerName: 'Start time',
    type: 'dateTime',
    width: 110,
    renderCell: renderWithProgress,
    valueFormatter: dateFormatter,
    valueGetter: (_, row) => row.dataSink?.startTime
  },
  {
    field: 'ds_end_time',
    headerName: 'End time',
    type: 'dateTime',
    width: 110,
    renderCell: renderWithProgress,
    valueFormatter: dateFormatter,
    valueGetter: (_, row) => row.dataSink?.endTime
  }
];

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: 'etl',
    headerName: 'Full ETL',
    headerAlign: 'center',
    freeReordering: true,
    renderHeaderGroup: params => (
      <GroupingHeader
        {...params}
        expandableColumns={['etl_start_time', 'etl_end_time', 'etl_success_rate']}
        collapsed={false}
      />
    ),
    children: [
      {field: 'etl_status'},
      {field: 'etl_duration'},
      {field: 'etl_start_time'},
      {field: 'etl_end_time'},
      {field: 'etl_success_rate'},
      {field: 'etl_parquet_rate'},
      {field: 'etl_glue_cost'}
    ]
  },
  {
    groupId: 'data_unifier',
    headerName: 'Data Unifier',
    headerAlign: 'center',
    renderHeaderGroup: params => (
      <GroupingHeader {...params} expandableColumns={['du_start_time', 'du_end_time']} collapsed={true} />
    ),
    freeReordering: true,
    children: [{field: 'du_status'}, {field: 'du_duration'}, {field: 'du_start_time'}, {field: 'du_end_time'}]
  },
  {
    groupId: 'data_enrichment',
    headerName: 'Data Enrichment',
    headerAlign: 'center',
    renderHeaderGroup: params => (
      <GroupingHeader {...params} expandableColumns={['de_start_time', 'de_end_time']} collapsed={true} />
    ),
    freeReordering: true,
    children: [{field: 'de_status'}, {field: 'de_duration'}, {field: 'de_start_time'}, {field: 'de_end_time'}]
  },
  {
    groupId: 'data_tailor',
    headerName: 'Data Tailor',
    headerAlign: 'center',
    freeReordering: true,
    renderHeaderGroup: params => (
      <GroupingHeader {...params} expandableColumns={['dt_start_time', 'dt_end_time']} collapsed={true} />
    ),
    children: [{field: 'dt_status'}, {field: 'dt_duration'}, {field: 'dt_start_time'}, {field: 'dt_end_time'}]
  },
  {
    groupId: 'data_sink',
    headerName: 'Data Sink',
    headerAlign: 'center',
    freeReordering: true,
    renderHeaderGroup: params => (
      <GroupingHeader {...params} expandableColumns={['ds_start_time', 'ds_end_time']} collapsed={true} />
    ),
    children: [{field: 'ds_status'}, {field: 'ds_duration'}, {field: 'ds_start_time'}, {field: 'ds_end_time'}]
  }
];

function transformToFullEtlData(
  tenants: Tenant[],
  tenantsData: TenantData[]
): FullEtlRow[] {
  return tenants
    .map((tenant) => {
      const tenantInfo = tenantsData.find(([id]) => id === tenant.uid)?.[1];

      const unifier = tenantInfo?.fullEtlRunTasks?.find(t => t.name === 'run-data-unifier-action');
      const enrichment = tenantInfo?.fullEtlRunTasks?.find(t => t.name === 'run-data-enrichment-action-zeus');
      const tailor = tenantInfo?.fullEtlRunTasks?.find(t => t.name === 'run-data-tailor-action');
      const sink = tenantInfo?.fullEtlRunTasks?.find(t => t.name === 'run-data-sink-action');

      const startTime = parseDate(unifier?.startTime || enrichment?.startTime || tailor?.startTime || sink?.startTime);
      const endTime = parseDate(sink?.endTime || tailor?.endTime || enrichment?.endTime || unifier?.endTime);
      const status = sink?.status || tailor?.status || enrichment?.status || unifier?.status;

      return {
        uid: tenant.uid,
        name: tenant.name,
        startTime: startTime?.getTime(),
        endTime: endTime?.getTime(),
        status: status,
        dataUnifier: unifier,
        dataEnrichment: enrichment,
        dataTailor: tailor,
        dataSink: sink,
        successRate: tenantInfo?.successRate,
        parquetRate: tenantInfo?.parquetRate,
        glueCost: tenantInfo?.glueCost,
        loading: tenantInfo === undefined
      } as FullEtlRow;
    });
}

export default function FullEtlTable({tenants, tenantsData, isLoading}: {
  tenants: Tenant[],
  tenantsData: TenantData[]
  isLoading: boolean
}) {
  const normalized = useMemo(() => transformToFullEtlData(tenants, tenantsData), [tenants, tenantsData]);
  return (
    <OpsDashboardTable
      initialState={{
        pinnedColumns: {left: ['id', 'tenant_name']},
        aggregation: {
          model: {
            etl_success_rate: 'successRateAgg',
            etl_parquet_rate: 'parquetRateAgg',
            etl_glue_cost: 'etlGlueCostAgg'
          }
        }
      }}
      rows={normalized}
      loading={isLoading}
      columns={columns}
      columnGroupingModel={columnGroupingModel}
      aggregationFunctions={{successRateAgg, parquetRateAgg, etlGlueCostAgg}}
    />
  );
}
