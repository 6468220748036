import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {ButtonProps} from '@mui/material';
import {GridColumnGroupHeaderParams, useGridApiContext} from '@mui/x-data-grid-premium';

export default function GroupingHeader(
  params: GridColumnGroupHeaderParams & {expandableColumns: string[]; collapsed: boolean}
) {
  const apiRef = useGridApiContext();
  const {headerName, expandableColumns, collapsed} = params;

  const [expanded, setExpanded] = useState<boolean>(!collapsed);

  useEffect(() => {
    expandableColumns.forEach(field => apiRef.current.setColumnVisibility(field, expanded));
  }, [expandableColumns, expanded, apiRef]);

  const handleClick: ButtonProps['onClick'] = event => {
    setExpanded(!expanded);
    event.stopPropagation();
  };

  return (
    <Box>
      <div>
        {headerName}
        <Button onClick={handleClick} size={'small'}>
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </Button>
      </div>
    </Box>
  );
}
