import {
  createAction,
  createSlice as createSliceOriginal,
  PrepareAction,
  SliceCaseReducers,
  CreateSliceOptions,
} from '@reduxjs/toolkit';
import {useInjectReducer as useReducer, useInjectSaga as useSaga} from 'redux-injectors';

import {InjectReducerParams, InjectSagaParams, RootStateKeyType} from './types/injectorTypings';

// TODO: Better type (2 overloads?) or separate function
export const createModuleAction =
  (moduleName: string) =>
  <Payload>(actionType: string, prepareAction?: PrepareAction<Payload>) => {
    const moduleActionType = `${moduleName}/${actionType}`;

    // Typescript can't resolve overloads in one-liner :(
    return prepareAction !== undefined
      ? createAction(moduleActionType, prepareAction)
      : createAction<Payload>(moduleActionType);
  };

export function useInjectReducer<Key extends RootStateKeyType>(params: InjectReducerParams<Key>) {
  return useReducer(params);
}

export function useInjectSaga(params: InjectSagaParams) {
  return useSaga(params);
}

export const createSlice = <State, CaseReducers extends SliceCaseReducers<State>, Name extends RootStateKeyType>(
  options: CreateSliceOptions<State, CaseReducers, Name>
) => {
  return createSliceOriginal(options);
};
