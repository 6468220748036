import {createApi, fetchBaseQuery, retry} from '@reduxjs/toolkit/query/react';
import {Tenant, TenantInfo} from '../../../pages/OpsDashboardPage/types';

const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_HOST,
  }),
  {maxRetries: 10}
);

export const tenantsApi = createApi({
  reducerPath: 'tenants',
  baseQuery,
  tagTypes: ['OpsDashboardTenant', 'OpsDashboardTenantInfo'],
  endpoints: build => ({
    getTenantList: build.query<Tenant[], TenantListQuery>({
      query: (params: TenantListQuery) => ({
        url: `dashboard/tenants`
      }),
      providesTags: (results, error, arg) => {
        return results
          ? [
            ...results.map(row => ({type: 'OpsDashboardTenant' as const, id: row.uid})),
            {type: 'OpsDashboardTenant', id: 'LIST'}
          ]
          : [{type: 'OpsDashboardTenant', id: 'LIST'}];
      }
    }),
    getTenantInfo: build.query<TenantInfo, TenantDataQuery>({
      query: (arg: TenantDataQuery) => ({
        url: `dashboard/tenants/${arg.tenantId}`
      }),
      providesTags: (result, error, arg) => {
        return result ?
          [{type: 'OpsDashboardTenantInfo', id: arg.tenantId}]
          : [{type: 'OpsDashboardTenantInfo', id: 'Undefined tenant'}];
      }
    })
  })
});

interface TenantListQuery {
  limit: number;
  offset: number;
}

interface TenantDataQuery {
  tenantId: string;
}

export const {useLazyGetTenantListQuery, useLazyGetTenantInfoQuery} = tenantsApi;
